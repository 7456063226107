import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgSchmuck01 from "../../assets/leistungen/schmuck01.jpg"
import imgSchmuck02 from "../../assets/leistungen/schmuck02.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Dachschmuck"
      Content="Schon im Altertum glaubte man, dass Tiere und andere Symbole auf dem Dachfirst die Bewohner vor Unheil bewahren. Auch heute noch heißt es vom Dachschmuck, dass er das Haus beschützt und Glück bringt. Das mag stimmen oder nicht: Zauberei ist bei den auffälligen Unikaten auf jeden Fall im Spiel, denn sie ziehen die Blicke geradezu magisch an."
    />
    <LeistungBox
      Color="gray"
      Image={imgSchmuck01}
      IsImageLeft={true}
      Title="Tradition aus der Antike"
      Content="Dachschmuck hat eine lange Tradition. Bereits die antiken Griechen und die geheimnisvollen Etrusker aus der Zeit um 800 vor Christus verwendeten Firstziegel mit verzierten Enden zum Schutz vor bösen Geistern. In Deutschland lässt sich die Verwendung von symbolischen Figuren auf dem Hausdach bis ins Mittelalter zurück verfolgen. Ein typisches Beispiel ist der Dachreiter, der vielen Kreuzrittern als weit sichtbares Zeichen ihrer Herkunft und ihres Standes diente. Später wurde der Dachfirst vor allem mit Tiersymbolen verziert, die das Haus vor Gefahr und ungebetenen Gästen bewahren sollten. Wer sich für den Fall der Fälle rüsten will, der wird auch heute noch fündig. So bietet das Braas Produktsortiment eine große Auswahl an keramischem Dachschmuck, der in sorgfältiger Handarbeit gefertigt und individuell bemalt wird."
    />
    <LeistungBox
      Color="white"
      Image={imgSchmuck02}
      IsImageLeft={false}
      Title="Handgearbeitete Einzelstücke"
      Content="Ein Beispiel dafür ist der Firsthahn. Er gilt als Symbol für Wachsamkeit und soll das Haus vor Feuer bewahren. Wer sich vor Einbrechern in der Nacht schützen möchte, sollte dagegen lieber zur tönernen Katze greifen: Diese verheißt im Volksglauben zudem noch Glück und Reichtum, sobald sie sich auf einem Dach niederlässt. Ein Glücksbringer der besonderen Art ist der Schlafwandler: Der etwa fünfzig Zentimeter kleine Geselle mit der Zipfelmütze soll vor schlaflosen Nächten schützen. Und wie alle anderen Braas Dachfiguren ist er ein sympathischer Blickfang, der jedem Haus eine ganz besondere, individuelle Note verleiht."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
